const rules = {
    emial: {
        regExp: /^[A-Za-zd0-9]+([-_.][A-Za-zd0-9]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/,
        errorText: '邮箱格式不正确，请重新输入'
    },
    phone: {
        regExp: /^1[3456789]\d{9}$/,
        errorText: '手机号格式不正确，请重新输入'
    }
}
export default rules