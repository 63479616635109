<template>
    <div class="page-info" v-loading="pageLoading" >
        <el-form ref="form" :model="form" :rules="rules" label-width="158px">
            <el-form-item label="供应商编码" required>
                <el-input v-model="form.supply_code" disabled></el-input>

                <el-button @click="resetRestPwdOpen()" style="margin-left:20px" v-if="form.status===2 || form.status===3">重置密码</el-button>
            </el-form-item>
            <el-form-item label="公司名称" prop="supply_company">
                <el-input v-model="form.supply_company"></el-input>
            </el-form-item>
            <el-row class="row-short">
                <el-col :span="12">
                    <el-form-item label="注册地址" prop="enroll_address">
                        <el-cascader ref="enrollAddr"
                            filterable
                            :options="regionOptions"
                            v-model="form.enroll_address">
                        </el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <!-- 详细地址 -->
                    <el-form-item label="" prop="enroll_address_detail" label-width="16px">
                        <el-input v-model="form.enroll_address_detail"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="row-short">
                <el-col :span="12">
                    <el-form-item label="办公地址" prop="business_address">
                        <el-cascader ref="enrollAddr"
                            filterable
                            :options="regionOptions"
                            v-model="form.business_address">
                        </el-cascader>
                        <!-- <el-cascader ref="businessAddr"
                            v-model="form.business_address"
                            :props="areaProps"
                            clearable>
                        </el-cascader> -->
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <!-- 办公地址 ~~ 详细地址 -->
                    <el-form-item label="" prop="business_address_detail" label-width="16px">
                        <el-input v-model="form.business_address_detail"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="成立日期" prop="found_time">
                <el-date-picker 
                    v-model="form.found_time"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd">
                    </el-date-picker>
            </el-form-item>
            <el-row class="row-short">
                <el-col :span="12">
                    <el-form-item label="商务联系人" prop="contact_name">
                        <el-input v-model="form.contact_name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="职位" prop="contact_position" label-width="100px">
                        <el-input v-model="form.contact_position"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="row-short">
                <el-col :span="12">
                   <el-form-item label="手机号码" prop="supply_phone" key="supplyPhone">
                        <el-input v-model="form.supply_phone" maxlength="11"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="邮箱" prop="supply_email" label-width="100px" key="supplyEmail">
                        <el-input v-model="form.supply_email"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="公司税号" prop="company_tax_no">
                <el-input v-model="form.company_tax_no"></el-input>
            </el-form-item>
            <el-form-item label="开户银行" prop="bank_name">
                <el-input v-model="form.bank_name"></el-input>
            </el-form-item>
            <el-form-item label="银行账号" prop="bank_no">
                <el-input v-model="form.bank_no"></el-input>
            </el-form-item>
            <el-form-item label="公司主要产品或服务" prop="company_service">
                <el-input
                    type="textarea"
                    :rows="4"
                    placeholder=""
                    v-model="form.company_service">
                </el-input>
            </el-form-item>
            <el-form-item label="公司资质及介绍" prop="company_instruction">
                <el-input
                    type="textarea"
                    :rows="4"
                    placeholder=""
                    v-model="form.company_instruction">
                </el-input>
            </el-form-item>
            <el-form-item label="营业执照/资质证书" prop="files">
                <el-upload
                    action="/api/v1/upload/file"
                    :before-upload="beforeUpload"
                    :on-success="uploadSuccess"
                    :show-file-list="false"
                    >
                    <el-button type="primary" plain size="small">点击上传</el-button>
                    <span slot="tip" class="el-upload__tip" style="margin-left:10px">
                        支持上传文件格式：excel，word，ppt，pdf，图片（jpg,png）,cad图和压缩包，且不超过8MB！</span>
                </el-upload>
                <ul class="upload-list">
                    <li v-for="(item,index) in fileList" :key="index">
                        <el-link @click="openFile(item.file_name,item.file_url)" type="primary">{{item.file_name}}</el-link>
                        <i class="el-icon-circle-close" @click="delFile(index)"></i>
                    </li>
                </ul>
            </el-form-item>
            <el-form-item class="btn-item">
                <el-button type="primary" plain @click="save()" :loading="loading" v-if="form.status===0||form.status===1||form.status===5">保存草稿</el-button>
                <el-button type="primary" @click="validateMes('approval')" :loading="loadingSubmit" v-if="form.status===0||form.status===1||form.status===5">提交</el-button>
                <el-button type="primary" @click="validateMes('update')" :loading="loading" v-if="form.status===2||form.status===3">修改</el-button>
            </el-form-item>
        </el-form>

        <dialog-reset-pwd 
            :open.sync="resetPwdOpen" 
            @dialogSuccess="closeRestPwdOpen">
        </dialog-reset-pwd>
    </div>
</template>
<script>
import { addSupplier, getSupplierInfo, supplierInfoUpdate, getAreaList,submitRegiste } from "@/api";
import rules from "@/utils/validateRules";
import DialogResetPwd from '../RestPwd'
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'

export default {
    name: 'detailInfo',
    components:{
        DialogResetPwd
    },
    props:{
        isEdit: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            pageLoading: false,
            loading: false,
            loadingSubmit: false,
            regionOptions: regionData,
            form:{
                found_time:'',
                supply_code: this.$store.state.user.supplyCode
            },
            fileList:[],
            rules:{
                supply_company: { required: true, message: '请输入公司名称', trigger: 'blur' },
                enroll_address: [{ required: true, message: '请选择注册省市区', trigger: 'change' }],
                enroll_address_detail: { required: true, message: '请输入注册的详细地址', trigger: 'blur' },
                business_address: { required: true, message: '请选择公司办公省市区', trigger: 'change' },
                business_address_detail: { required: true, message: '请选择公司办公详细地址', trigger: 'blur' },
                found_time: { type: 'string', required: true, message: '请选择日期', trigger: 'change' },
                contact_name: [{ required: true, message: '请输入商务联系人', trigger: 'blur' }],
                contact_position: { required: true, message: '请输入职位', trigger: 'blur' },
                supply_email: [
                    { required: true, trigger: 'blur', validator: (rule, value, callback) => {
                        let reg = rules.emial.regExp
                        if(!value){
                            return callback(new Error('请输入邮箱'));
                        } else {
                            if(!reg.test(value)){
                                return callback(new Error(rules.emial.errorText));
                            }
                            callback();
                        }
                    }}
                ],
                supply_phone: [
                     { required: true, trigger: 'blur', validator: (rule, value, callback) => {
                        let reg = rules.phone.regExp
                        if(!value){
                            return callback(new Error('请输入手机号码'));
                        } else {
                            if(!reg.test(value)){
                                return callback(new Error(rules.phone.errorText));
                            }
                            callback();
                        }
                    }}
                ],
                company_tax_no: { required: true, message: '请输入公司税号', trigger: 'blur' },
                bank_name: { required: true, message: '请输入开户银行', trigger: 'blur' },
                bank_no: { required: true, message: '请输入银行账号', trigger: 'blur' },
                company_service: { required: true, message: '请输入公司主要产品或服务', trigger: 'blur' },
                company_instruction: { required: true, message: '请输入公司资质及介绍', trigger: 'blur' },
                files: [{ required: true, validator: (rule, value, callback) => {
                        if(this.fileList.length === 0){
                            return callback(new Error('请上传合同附件'));
                        } else {
                            callback();
                        }
                    }}
                ]
            },
            resetPwdOpen: false,
            fileType:''
        }
    },
    created(){
        this.initform()
    },
    methods: {
        /* 上传图片 Start */
        beforeUpload(file) {
            const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLocaleUpperCase()

            const isExcel = testmsg === 'XLS' || testmsg === 'XLSX';
            const isDoc = testmsg === 'DOC' || testmsg === 'DOCX';
            const isPPT = testmsg === 'PPT' || testmsg === 'PPTX';
            const isPDF = testmsg === 'PDF';
            const isImg = testmsg === 'JPG' || testmsg === 'JPEG' || testmsg === 'PNG';
            const isCad = testmsg === 'DWG' || testmsg === 'DXF' || testmsg === 'DWT' || testmsg === 'DWS';
            const isCompress = testmsg === 'RAR' || testmsg === 'ZIP' || testmsg === 'RAR4';
            
            const isLt8M = file.size / 1024 / 1024 < 8;

            if(!isPDF && !isExcel && !isImg && !isDoc && !isPPT && !isCad && !isCompress){
                this.$message.error('只支持上传文件格式：excel，word，ppt，pdf，图片（jpg,png）,cad图和压缩包!');
                return false
            }
             if (!isLt8M) {
                this.$message.error('上传文件大小不能超过 8MB!');
                return false
            }
        },
        uploadSuccess(res,file,fileList){
            if(res.code !== 0){
                return this.$message.error(res.msg)
            }

            this.fileList.push(res.data)
            this.$refs.form.validateField('files')
        },
        delFile(index){
            this.fileList.splice(index, 1);
        },
        //预览文件
        openFile(name, url){
            const type = name.replace(/.*\./, "").toLocaleUpperCase()
            if(type.indexOf('XLS') > -1 || type.indexOf('DOC') > -1 || type.indexOf('PPT') > -1 ){
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
            } else {
                window.open(url)
            }
        },
        /* 上传图片 END */


        initform(){
            this.pageLoading = true
            getSupplierInfo().then((res)=>{
                const { data } = res;
                this.form = data;
                this.fileList = data.business_license_files || []
            }).catch(err => {
                // console.log(err,'异常')
            }).finally(()=>{
                this.pageLoading = false
            })
        },
        resetRestPwdOpen(){
            this.resetPwdOpen = true
        },
        closeRestPwdOpen(){
            this.resetPwdOpen = false
        },
        
        //分隔上传文件id
        filterFilesId(){
            let ids = [];
            ids = this.fileList.map(item => {
                return item.file_id
            });
            return ids
        },

        //保存草稿
        save(){
            let valiSome = ['supply_phone','supply_email']
            let flag = true;
            valiSome.forEach((item,key) => {
                this.$refs.form.validateField(item, valid => {
                    if(valid.length){
                        return flag = false
                    }
                })
            })
            if(flag){
                let params = {...this.form}
                if(this.fileList.length > 0){
                    params.business_license = this.filterFilesId();
                }
                this.loading = true;
                addSupplier(params).then((res)=>{
                    this.$store.commit('user/SET_STATUS',5)
                    this.initform();

                    this.$alert("保存草稿成功！", "提示", {
                        confirmButtonText: "返回列表",
                        callback: action => {
                            this.$router.push({name: 'Information'})
                        }
                    });
                }).catch(err => {
                    // console.log(err,'异常')
                }).finally(()=>{
                    this.loading = false
                })
            }
        },



        //校验form标的
        validateMes(type){   
            this.$refs.form.validateField('files')
            this.$refs.form.validate((valid) => {
                console.log(valid)
                if (valid) {
                    let params = {...this.form}
                    params.business_license = this.filterFilesId();

                    if(type=="update"){
                        this.updateForm(params);
                    } else if(type=="approval"){
                        this.submitApproval(params);
                    }
                }
            });
        },

        //提交审批
        submitApproval(params){
            this.$confirm('请确认是否继续提交审批？', '提示', {
                confirmButtonText: '继续',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingSubmit = true
                submitRegiste(params).then((res)=>{
                    this.$alert("提交成功，等待审核", "提示", {
                        confirmButtonText: "返回列表",
                        callback: action => {
                            this.$router.push({name: 'Information'})
                        }
                    });
                }).catch(err => {
                    // console.log(err,'异常')
                }).finally(()=>{
                    this.loadingSubmit = false
                })
            }).catch(() => {  
                console.log("取消")
            });
        },


        //修改信息
        updateForm(params){
            const _this = this;

            this.$confirm('请确认是否继续修改信息？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                supplierInfoUpdate(params).then((res)=>{
                    this.$alert('修改成功！等待审批', '成功', { type:'success'})
                }).catch(err => {
                    // console.log(err,'异常')
                }).finally(()=>{
                    this.loading = false
                })
            }).catch(() => {
                // this.loading = false
            });


        }
    }
}
</script>
<style lang="scss" scoped>
$labelWidth: 158px;
$shortInput: 224px;
.page-info{
    .el-form{
        width: 900px;
        margin: 0 auto;
        padding: 30px 0;
        .el-input,.el-textarea{
            max-width: 400px
        }
        .el-cascader, .el-date-editor{
            width: $shortInput
        }

        .el-row.row-short{
            &>.el-col{
                width: ($labelWidth + $shortInput);
            }
        }
    }
    .upload-file{
        a{
            color: #409eff;
            cursor: pointer;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
        .del-file{
            display: none;
            font-size: 14px;
            cursor: pointer;
        }

        .type-img{
            width:400px;
            border: 1px solid #c0ccda;
            border-radius: 6px;
            box-sizing: border-box;
            margin-top: 10px;
            padding: 10px 10px 10px 90px;
            height: 92px;
            position: relative;
            .file-img{
                vertical-align: middle;
                display: inline-block;
                width: 70px;
                height: 70px;
                float: left;
                position: relative;
                z-index: 1;
                margin-left: -80px;
                background-color: #fff;
            }
            .file-name{
                line-height: 70px;
                margin-top: 0;
                display: block;
            }
            .del-file{
                position: absolute;
                right: 5px;
                top: 5px;
            }
        }
        .type-pdf{
            vertical-align: middle;
            .del-file{
                vertical-align: middle;
                margin-left: 14px;
            }
        }
        .type-img,.type-pdf{
            &:hover{
                .del-file{ display: inline-block;}
            }
        }
    }
    .btn-item{
        .el-button{
            padding: 14px 100px;
            font-size: 16px;
        }
    }
}
</style>